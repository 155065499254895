import React, { useEffect, useState } from "react";
import axios from "axios";
import "./UserHistory.css"; // Import CSS
import config from "../config";
const apiUrl = process.env.REACT_APP_API_URL;

const UserHistory = ({ userInfo }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const role = localStorage.getItem("role");

  useEffect(() => {
    if (userInfo.role != "admin") {
      window.location.href = "/dashboard";
      return;
    }
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/history/get-all`);
      setData(response.data);
    } catch (error) {
      console.error("Failed to load data");
    }
    setLoading(false);
  };

  return (
    <div className="table-container">
      <h2>Lịch sử người dùng</h2>
      {loading ? (
        <div className="loader">Loading...</div>
      ) : (
        <table className="styled-table">
          <thead>
            <tr>
              <th>Tên người dùng</th>
              <th>Hành động</th>
              <th>Thời gian</th>
            </tr>
          </thead>
          <tbody>
            {data.map((log) => (
              <tr key={log._id}>
                <td>{log.username}</td>
                <td>{log.action}</td>
                <td>{new Date(log.timestamp).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default UserHistory;
