import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Main from "./components/layout/Main";
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
// import config from "./config";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "antd/dist/antd.css";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import Profile from "./pages/Profile";
import CreateTransfer from "./pages/CreateTransfer";
import TransferSlipDetail from "./pages/TransferSlipDetail";
import UserHistory from "./pages/UserHistory";
import ReceivedTransferForm from "./pages/ReceivedTransferForm";
import ChangeTransfer from "./pages/ChangeTransfer";
const apiUrl = process.env.REACT_APP_API_URL;

const initUserInfo = {
  userId: "",
  username: "",
  fullName: "",
  role: "",
  position: "",
  permissions: "",
};

function App() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenicated] = useState(false);
  const [userInfo, setUserInfo] = useState(initUserInfo);

  useEffect(() => {
    const token = localStorage.getItem("token") || "";

    axios
      .get(`${apiUrl}/user/verify-token`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Tạo object chứa toàn bộ thông tin user
        const userInfo = {
          userId: response.data.user.userId,
          username: response.data.user.username,
          fullName: response.data.user.fullName,
          role: response.data.user.role,
          position: response.data.user.position,
          permissions: response.data.user.permissions,
        };

        // Lưu object vào localStorage dưới dạng chuỗi JSON
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        setUserInfo(userInfo);
        setIsAuthenicated(true);
      })
      .catch((error) => {
        navigate("/login");
      });
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Main userInfo={userInfo} />}>
          <Route path="" element={<Dashboard userInfo={userInfo} />} />
          <Route path="profiles" element={<Profile userInfo={userInfo} />} />
          <Route
            path="create-transfer"
            element={<CreateTransfer userInfo={userInfo} />}
          ></Route>
          <Route
            path="transfer-slip-detail"
            element={<TransferSlipDetail userInfo={userInfo} />}
          />
          <Route
            path="user-history"
            element={<UserHistory userInfo={userInfo} />}
          />
          <Route
            path="received-transfer"
            element={<ReceivedTransferForm userInfo={userInfo} />}
          />

          <Route
            path="change-transfer"
            element={<ChangeTransfer userInfo={userInfo} />}
          />

          {/* Nếu đường dẫn không hợp lệ, chuyển hướng về trang chủ */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
        <Route path="/login" element={<SignIn />} />
      </Routes>
      <Toaster />
    </>
  );
}

export default App;
