import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Table,
  InputNumber,
  message,
  Modal,
  Radio,
} from "antd";
import "antd/dist/antd.css";
import { debounce } from "lodash";
import axios from "axios";
import moment from "moment";
import data from "../data.json";

const { Option } = Select;
const { TextArea } = Input;

const CreateTransfer = () => {
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [transferForNextDay, setTransferForNextDay] = useState(false);

  const createAbbreviation = (name) => {
    return name
      .split(" ")
      .map((word) => word.charAt(0).toLowerCase())
      .join("");
  };

  const [availableProducts] = useState(
    data.map((product) => ({
      ...product,
      keywords: [...product.keywords, createAbbreviation(product.name)],
    }))
  );

  const normalizeString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const handleSearch = debounce(() => {
    if (searchText) {
      const searchTerm = normalizeString(searchText.trim());

      const filteredSuggestions = availableProducts
        .map((product, index) => {
          const combinedKeywords = product.keywords.map(normalizeString);
          const matchIndex = combinedKeywords.findIndex((keyword) =>
            keyword.includes(searchTerm)
          );

          return {
            product,
            matchIndex,
            originalIndex: index,
          };
        })
        .filter(({ matchIndex }) => matchIndex !== -1)
        .sort((a, b) => {
          if (a.matchIndex === b.matchIndex) {
            return a.originalIndex - b.originalIndex;
          }
          return a.matchIndex - b.matchIndex;
        })
        .slice(0, 15)
        .map(({ product }) => ({
          value: `${product.code} - ${product.name} - ${product.category}`,
          product,
        }));

      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  }, 300);

  useEffect(() => {
    handleSearch();
  }, [searchText]);

  const handleSelectProduct = (selectedProduct) => {
    const isProductAlreadySelected = selectedProducts.some(
      (product) => product.code === selectedProduct.product.code
    );

    if (isProductAlreadySelected) {
      message.warning(
        `Sản phẩm ${selectedProduct.product.name} đã tồn tại trong danh sách.`
      );
    } else {
      setSelectedProducts([
        ...selectedProducts,
        { ...selectedProduct.product, quantity: 1 },
      ]);
      message.success(`${selectedProduct.product.name} đã được thêm vào bảng.`);
    }

    setSuggestions([]);
    setSearchText("");
  };

  const handleQuantityChange = (index, value) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts[index].quantity = value;
    setSelectedProducts(updatedProducts);
  };

  const removeProductFromSelected = (index) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts.splice(index, 1); // Remove the product at the given index
    setSelectedProducts(updatedProducts);
  };

  const onFinish = async (values) => {
    if (selectedProducts.length === 0) {
      message.error(
        "Vui lòng chọn ít nhất một sản phẩm trước khi xuất phiếu điều chuyển!"
      );
      return;
    }

    const currentDateTime = transferForNextDay
      ? moment()
          .add(1, "days")
          .set({ hour: 6, minute: 0, second: 0 })
          .format("DD-MM-YYYY (HH:mm:ss)")
      : moment().format("DD-MM-YYYY (HH:mm:ss)");

    Modal.confirm({
      title: "Xác nhận",
      content: "Bạn có chắc chắn muốn xuất phiếu điều chuyển này?",
      okText: "Xác nhận",
      cancelText: "Hủy bỏ",
      onOk: async () => {
        const selectedProductDetails = selectedProducts.map((product) => ({
          code: product.code,
          name: product.name,
          category: product.category,
          unit: product.unit,
          quantity: product.quantity,
        }));

        const transferDetails = {
          datetime: currentDateTime,
          transport_type: values.transport_type,
          notes: values.notes || "",
          selectedProducts: selectedProductDetails,
        };

        const token = localStorage.getItem("token");

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/transfer/create`,
            transferDetails,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          Modal.success({
            title: "Xác nhận",
            content: "Xuất phiếu điều chuyển thành công!",
            onOk: () => {
              window.location.href = `/transfer-slip-detail?trcode=${response.data.transferId}`;
            },
          });
        } catch (error) {
          console.error("Error creating transfer slip:", error);
          message.error("Có lỗi xảy ra khi xuất phiếu điều chuyển");
        }
      },
    });
  };

  const columns = [
    {
      title: "Mã sản phẩm",
      dataIndex: "code",
      key: "code",
      width: 100,
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Danh mục",
      dataIndex: "category",
      key: "category",
      width: 150,
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "quantity",
      width: 100,
      render: (quantity, record, index) => (
        <InputNumber
          min={0.01}
          step={0.01}
          // value={quantity}
          inputMode="decimal"
          type="number"
          formatter={(value) => {
            if (value && value.toString().includes(".")) {
              return value.replace(/(\.0+|(?<=\.\d{1,9})0+)$/, "");
            }
            return value;
          }}
          onChange={(value) => handleQuantityChange(index, value)}
          style={{ width: "100px" }}
        />
      ),
    },
    {
      title: "Đơn vị tính",
      dataIndex: "unit",
      key: "unit",
      width: 100,
    },
    {
      title: "Hành động",
      key: "action",
      width: 100,
      render: (_, record, index) => (
        <Button type="link" onClick={() => removeProductFromSelected(index)}>
          Xóa
        </Button>
      ),
    },
  ];

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item
        name="transport_type"
        label="Loại phiếu"
        rules={[{ required: true, message: "Vui lòng chọn loại phiếu!" }]}
      >
        <Select placeholder="Chọn loại phiếu" style={{ width: "100%" }}>
          <Option value="lanh_vat_tu_san_xuat">Lãnh Vật Tư Sản Xuất</Option>
          <Option value="tra_vat_tu_dang_do">Trả Vật Tư Sản Xuất</Option>
          <Option value="xuat_ban_thanh_pham">Lãnh bán thành phẩm</Option>
          <Option value="nhap_ban_thanh_pham">Trả bán thành phẩm</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Ngày chuyển">
        <Radio.Group
          onChange={(e) => setTransferForNextDay(e.target.value === "tomorrow")}
          defaultValue="today"
        >
          <Radio value="today">Cho hôm nay</Radio>
          <Radio value="tomorrow">Cho ngày mai</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item name="notes" label="Ghi chú">
        <TextArea rows={4} />
      </Form.Item>
      <Input
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Tìm kiếm sản phẩm"
        style={{ width: "100%", marginBottom: 16 }}
      />
      {suggestions.length > 0 && (
        <div
          style={{
            border: "1px solid #d9d9d9",
            padding: "8px",
            borderRadius: "4px",
            marginBottom: "16px",
          }}
        >
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              style={{ padding: "8px", cursor: "pointer" }}
              onClick={() => handleSelectProduct(suggestion)}
            >
              {suggestion.value} {/* Tên sản phẩm (mã) - Danh mục */}
            </div>
          ))}
        </div>
      )}
      <Table
        columns={columns}
        dataSource={selectedProducts}
        rowKey={(record, index) => index}
        pagination={false}
        scroll={{ x: "100%" }}
        style={{ marginTop: 20 }}
      />
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Xuất phiếu điều chuyển
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateTransfer;
