import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  Table,
  Input,
  Form,
  Typography,
  Modal,
  Button,
  Spin,
  Alert,
  DatePicker,
  Row,
  Col,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";

const apiUrl = process.env.REACT_APP_API_URL;
const { Title } = Typography;

const initState = {
  transport_type: "",
  responsiblePerson: "",
  status: "",
  notes: "",
  products: [],
};

const ChangeTransfer = () => {
  const [transferDetails, setTransferDetails] = useState(initState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const trcode = searchParams.get("trcode");

  useEffect(() => {
    const fetchTransferDetails = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/transfer/transfer-details/${trcode}`
        );
        const formattedData = formatData(response.data);
        setTransferDetails(formattedData);
        setLoading(false);
      } catch (error) {
        setError("Error fetching transfer details");
        setLoading(false);
      }
    };

    fetchTransferDetails();
  }, [trcode]);

  const formatData = (data) => {
    return {
      transport_type:
        data.transport_type === "lanh_vat_tu_san_xuat"
          ? "Lãnh Vật Tư Sản Xuất"
          : "Trả Vật Tư Sản Xuất",
      responsiblePerson: data.responsiblePerson,
      status: data.status,
      notes: data.notes,
      selectedProducts: data.products.map((product, index) => ({
        key: `${product.code}_${product.lot || index}_${product.date || index}`,
        code: product.code,
        name: product.name,
        category: product.category,
        unit: product.unit,
        quantity: product.quantity,
        availableQuantity: product.quantity, // Số lượng sẵn có ban đầu bằng với SL Đề nghị
        lot: product.lot || "",
        date: product.lotDate ? moment(product.lotDate, "DD/MM/YYYY") : null,
        isSubProduct: false,
      })),
    };
  };

  const handleConfirm = async () => {
    try {
      const values = await form.validateFields();

      const hasDuplicateLotDate = transferDetails.selectedProducts.some(
        (product, index) => {
          return transferDetails.selectedProducts.some(
            (p, i) =>
              i !== index &&
              p.code === product.code &&
              p.lot === product.lot &&
              p.date &&
              product.date &&
              p.date.isSame(product.date, "day")
          );
        }
      );

      if (hasDuplicateLotDate) {
        Modal.error({
          title: "Xác nhận thất bại",
          content: "Cùng một sản phẩm không thể có cùng Lot Date.",
        });
        return;
      }

      // Kiểm tra tổng số lượng của vật tư gốc và vật tư con
      // const productQuantities = {};
      // transferDetails.selectedProducts.forEach((product) => {
      //   if (!productQuantities[product.code]) {
      //     productQuantities[product.code] = 0;
      //   }
      //   productQuantities[product.code] +=
      //     parseFloat(values[`availableQuantity_${product.key}`]) || 0;
      // });

      // for (let code in productQuantities) {
      //   const originalProduct = transferDetails.selectedProducts.find(
      //     (product) => product.code === code && !product.isSubProduct
      //   );
      //   if (productQuantities[code] > originalProduct.quantity) {
      //     Modal.error({
      //       title: "Xác nhận thất bại",
      //       content: `Tổng số lượng của sản phẩm ${originalProduct.name} vượt quá số lượng yêu cầu.`,
      //     });
      //     return;
      //   }
      // }

      // Kiểm tra ngày không được là ngày trong quá khứ
      for (let product of transferDetails.selectedProducts) {
        const date = values[`date_${product.key}`];
        if (date && date.isBefore(moment(), "day")) {
          Modal.error({
            title: "Xác nhận thất bại",
            content: `Ngày cho sản phẩm ${product.name} không thể là ngày trong quá khứ.`,
          });
          return;
        }
      }

      // Kiểm tra nếu có sản phẩm nào có số lượng là 0
      for (let product of transferDetails.selectedProducts) {
        if (parseFloat(values[`availableQuantity_${product.key}`]) === 0) {
          Modal.error({
            title: "Xác nhận thất bại",
            content: `Số lượng cho sản phẩm ${product.name} không thể là 0.`,
          });
          return;
        }
      }

      // Nếu không có lỗi, tiếp tục cập nhật sản phẩm
      const updatedProducts = transferDetails.selectedProducts.map(
        (product) => ({
          ...product,
          lot: values[`lot_${product.key}`],
          date: values[`date_${product.key}`]
            ? values[`date_${product.key}`].format("DD/MM/YYYY")
            : "",
          availableQuantity: values[`availableQuantity_${product.key}`],
        })
      );

      const dataToSend = {
        transferId: trcode,
        selectedProducts: updatedProducts,
      };

      const token = localStorage.getItem("token");

      // const response = await axios.post(
      //   `${apiUrl}/transfer/slipt-transfer`,
      //   dataToSend,
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );

      const response = await axios.post(
        `${apiUrl}/transfer/test-transfer`,
        dataToSend
      );

      if (response.status === 200) {
        Modal.success({
          title: "Xác nhận thành công",
          content: "Thông tin phiếu đã được cập nhật.",
        });
        window.location.href = "/";
      } else {
        throw new Error("Có lỗi xảy ra khi cập nhật thông tin");
      }
    } catch (error) {
      console.error("API Error:", error);
      Modal.error({
        title: "Xác nhận thất bại",
        content: "Đã xảy ra lỗi khi cập nhật thông tin phiếu.",
      });
    }
  };

  const handleDuplicateRow = (index) => {
    const newProducts = [...transferDetails.selectedProducts];
    const originalProduct = newProducts[index];

    // Tính tổng số lượng sẵn có của tất cả các sản phẩm khác có cùng SKU
    const totalAllocatedQuantity = newProducts.reduce((sum, product, i) => {
      if (product.code === originalProduct.code && i !== index) {
        return sum + product.availableQuantity;
      }
      return sum;
    }, originalProduct.availableQuantity); // Bắt đầu từ số lượng hiện tại của sản phẩm gốc

    // Kiểm tra nếu tổng số lượng vượt quá số lượng gốc
    if (totalAllocatedQuantity >= originalProduct.quantity) {
      Modal.error({
        title: "Không thể tách sản phẩm",
        content: `Số lượng sẵn có của sản phẩm ${originalProduct.name} đã đạt tới hoặc vượt quá số lượng yêu cầu.`,
      });
      return;
    }

    // Nếu không vượt quá, tiếp tục thực hiện Duplicate
    const newProduct = { ...originalProduct };
    newProduct.key = `${newProduct.code}_${newProduct.lot || index + 1}_${
      newProduct.date || index + 1
    }_${Date.now()}`;
    newProduct.isSubProduct = true;
    newProduct.availableQuantity = 0;

    // Thêm sản phẩm mới vào danh sách
    newProducts.splice(index + 1, 0, newProduct);
    setTransferDetails({
      ...transferDetails,
      selectedProducts: newProducts,
    });
  };

  const handleRemoveRow = async (index) => {
    const newProducts = [...transferDetails.selectedProducts];
    // Xóa sản phẩm tại vị trí chỉ định
    newProducts.splice(index, 1);

    // Cập nhật lại state với danh sách sản phẩm mới
    setTransferDetails({
      ...transferDetails,
      selectedProducts: newProducts,
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      width: "40px",
      align: "center",
      render: (_, __, index) => index + 1,
    },
    {
      title: "SKU",
      dataIndex: "code",
      key: "code",
      width: "60px",
      align: "center",
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
      width: "200px",
    },
    {
      title: "SL Đề nghị",
      dataIndex: "quantity",
      key: "quantity",
      width: "80px",
      align: "center",
    },
    {
      title: "ĐVT",
      dataIndex: "unit",
      key: "unit",
      width: "60px",
      align: "center",
    },
    {
      title: "Số lượng sẵn có",
      key: "availableQuantity",
      width: "120px",
      render: (_, record, index) => (
        <Form.Item
          name={`availableQuantity_${record.key}`}
          initialValue={record.availableQuantity}
          rules={[
            {
              required: true,
              message: "Số lượng sẵn có không được để trống",
            },
            // {
            //   validator: (_, value) =>
            //     value > record.quantity
            //       ? Promise.reject(
            //           new Error(
            //             "Số lượng sẵn có không được vượt quá số lượng đề nghị"
            //           )
            //         )
            //       : Promise.resolve(),
            // },
          ]}
        >
          <Input
            type="number"
            step="0.01"
            placeholder="Nhập số lượng sẵn có"
            onChange={(e) => {
              const newProducts = [...transferDetails.selectedProducts];
              newProducts[index].availableQuantity =
                parseFloat(e.target.value) || 0;
              setTransferDetails({
                ...transferDetails,
                selectedProducts: newProducts,
              });
            }}
          />
        </Form.Item>
      ),
    },

    // {
    //   title: "Số lượng thực nhận",
    //   key: "receivedQuantity",
    //   width: "120px",
    //   render: (_, record, index) => (
    //     <div style={{ display: "flex", alignItems: "center" }}>
    //       <Form.Item
    //         name={`receivedQuantity_${record.key}`}
    //         initialValue={record.dispatchedQuantity} // Tự động điền giá trị từ "Số lượng thực xuất"
    //         rules={[
    //           {
    //             required: true,
    //             message: "Số lượng thực nhận không được để trống",
    //           },
    //           // {
    //           //   validator: (_, value) =>
    //           //     value > record.dispatchedQuantity
    //           //       ? Promise.reject(
    //           //           new Error(
    //           //             "Số lượng thực nhận không được vượt quá số lượng thực xuất"
    //           //           )
    //           //         )
    //           //       : Promise.resolve(),
    //           // },
    //         ]}
    //         style={{ flex: 1 }}
    //       >
    //         <Input
    //           type="number"
    //           step="0.01"
    //           placeholder="Nhập số lượng thực nhận"
    //           disabled={transferDetails.status === "approved"}
    //           style={{
    //             color: "red", // Màu chữ trong Input
    //             textAlign: "center",
    //             fontWeight: "bold",
    //           }}
    //           onChange={(e) => {
    //             const newProducts = [...transferDetails.selectedProducts];
    //             newProducts[index].receivedQuantity =
    //               parseFloat(e.target.value) || 0;
    //             setTransferDetails({
    //               ...transferDetails,
    //               selectedProducts: newProducts,
    //             });
    //           }}
    //         />
    //       </Form.Item>
    //     </div>
    //   ),
    // },
    {
      title: "Lot",
      key: "lot",
      width: "120px",
      render: (_, record, index) => (
        <Form.Item name={`lot_${record.key}`} initialValue={record.lot}>
          <Input
            placeholder="Nhập Lot"
            // disabled={
            //   transferDetails.status === "inProgress" && !record.isSubProduct
            // }
            onChange={(e) => {
              const newProducts = [...transferDetails.selectedProducts];
              newProducts[index].lot = e.target.value;
              setTransferDetails({
                ...transferDetails,
                selectedProducts: newProducts,
              });
            }}
            style={{ width: "150px" }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Date",
      key: "date",
      width: "120px",
      render: (_, record, index) => (
        <Form.Item name={`date_${record.key}`} initialValue={record.date}>
          <DatePicker
            format="DD/MM/YYYY"
            placeholder="Chọn ngày"
            // disabled={
            //   transferDetails.status === "inProgress" && !record.isSubProduct
            // }
            onChange={(date, dateString) => {
              const newProducts = [...transferDetails.selectedProducts];
              newProducts[index].date = date
                ? moment(dateString, "DD/MM/YYYY")
                : null;
              setTransferDetails({
                ...transferDetails,
                selectedProducts: newProducts,
              });
            }}
            style={{ width: "150px" }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Thao tác",
      key: "actions",
      width: "150px",
      render: (_, record, index) => (
        <div>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={() => handleDuplicateRow(index)}
            style={{ marginRight: 8 }}
          />
          {record.isSubProduct && (
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              onClick={() => handleRemoveRow(index)}
            />
          )}
        </div>
      ),
    },
  ];

  if (loading) return <Spin size="large" />;
  if (error) return <Alert message={error} type="error" showIcon />;

  return (
    <div style={{ padding: "16px" }}>
      <Title level={3} style={{ textAlign: "center" }}>
        Thông tin phiếu nhận hàng
      </Title>
      <Form form={form} layout="vertical">
        <Table
          columns={columns}
          dataSource={transferDetails.selectedProducts}
          rowKey="key"
          pagination={false}
          tableLayout="auto"
          scroll={{ x: 1000 }}
          style={{ width: "100%" }}
        />
        <Row justify="center" style={{ marginTop: "20px" }}>
          <Col span={24}>
            <Form.Item>
              <Button
                disabled={transferDetails.status == "pending"}
                type="primary"
                size="large"
                onClick={handleConfirm}
                block
              >
                Xác nhận
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ChangeTransfer;
