import React, { useEffect } from "react";
import { Modal } from "antd";
import Quagga from "quagga";
import toast from "react-hot-toast";
import "./ScanToConfirmModal.css"; // Import CSS tùy chỉnh

const ScanToConfirmModal = ({ transferId, visible, onClose }) => {
  useEffect(() => {
    if (visible) {
      Quagga.init(
        {
          inputStream: {
            type: "LiveStream",
            target: document.querySelector("#barcode-scanner"), // Gắn camera vào một phần tử HTML
            constraints: {
              width: 400,
              height: 300,
              facingMode: "environment", // Sử dụng camera sau
            },
          },
          decoder: {
            readers: ["code_128_reader", "ean_reader"], // Các loại mã vạch cần nhận diện
          },
        },
        function (err) {
          if (err) {
            console.error(err);
            return;
          }
          Quagga.start();
        }
      );

      Quagga.onDetected((data) => {
        if (data.codeResult.code === transferId) {
          Quagga.stop();
          toast.success("Scan thành công, mã phiếu hợp lệ.", {
            position: "top-right",
          });
          onClose();
          window.location.href = `/received-transfer?trcode=${transferId}`;
        } else {
          toast.error("Mã phiếu không khớp. Vui lòng thử lại.", {
            position: "top-right",
          });
        }
      });

      return () => {
        Quagga.offDetected();
        Quagga.stop();
      };
    }
  }, [visible, transferId, onClose]);

  return (
    <Modal
      title="Scan mã vạch để xác nhận"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={500}
    >
      <div id="barcode-scanner" className="barcode-scanner"></div>
    </Modal>
  );
};

export default ScanToConfirmModal;
