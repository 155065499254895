import React, { useEffect, useState } from "react";
import TransferTable from "../components/TransferTable";
import axios from "axios";
import config from "../config";
import moment from "moment";
const apiUrl = process.env.REACT_APP_API_URL;

const Dashboard = ({ userInfo }) => {
  const [transfers, setTransfers] = useState([]);
  const [filteredTransfers, setFilteredTransfers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState(null);
  const [selectedDates, setSelectedDates] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const fetchDataTransfer = () => {
    // Lấy token từ localStorage
    const token = localStorage.getItem("token");

    axios
      .get(`${apiUrl}/transfer/get-all-transfer`, {
        headers: {
          Authorization: `Bearer ${token}`, // Truyền token vào header
        },
      })
      .then((response) => {
        setTransfers(response.data.data);
        setFilteredTransfers(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDataTransfer();
  }, []);

  useEffect(() => {
    let updatedTransfers = [...transfers];

    if (searchTerm) {
      updatedTransfers = updatedTransfers.filter((transfer) =>
        transfer.transferId.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (filterType) {
      updatedTransfers = updatedTransfers.filter(
        (transfer) => transfer.transport_type === filterType
      );
    }

    if (selectedDates) {
      const startDate = selectedDates[0].startOf("day");
      const endDate = selectedDates[1].endOf("day");

      updatedTransfers = updatedTransfers.filter((transfer) => {
        // Chuyển đổi ngày từ dữ liệu sang định dạng moment để so sánh
        const transferDate = moment(transfer.date, "DD-MM-YYYY").startOf("day");
        return transferDate.isBetween(startDate, endDate, null, "[]"); // Sử dụng '[]' để bao gồm cả startDate và endDate trong khoảng
      });
    }

    if (sortOption) {
      if (sortOption === "time_desc") {
        updatedTransfers.sort((a, b) =>
          moment(b.date + " " + b.time, "DD-MM-YYYY HH:mm:ss").diff(
            moment(a.date + " " + a.time, "DD-MM-YYYY HH:mm:ss")
          )
        );
      } else if (sortOption === "time_asc") {
        updatedTransfers.sort((a, b) =>
          moment(a.date + " " + a.time, "DD-MM-YYYY HH:mm:ss").diff(
            moment(b.date + " " + b.time, "DD-MM-YYYY HH:mm:ss")
          )
        );
      }
    }
    setFilteredTransfers(updatedTransfers);
  }, [searchTerm, filterType, selectedDates, sortOption, transfers]);

  return (
    <div className="p-8 bottom-12">
      <TransferTable
        userInfo={userInfo}
        transfers={filteredTransfers}
        setSearchTerm={setSearchTerm}
        setFilterType={setFilterType}
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        setSortOption={setSortOption}
        fetchDataTransfer={fetchDataTransfer}
      />
    </div>
  );
};

export default Dashboard;
