import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../config";
const apiUrl = process.env.REACT_APP_API_URL;

const ChangePassword = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    const { currentPassword, newPassword, confirmNewPassword } = values;
    const token = localStorage.getItem("token") || "";

    if (newPassword !== confirmNewPassword) {
      message.error("Mật khẩu mới và xác nhận mật khẩu mới không khớp.");
      return;
    }

    setLoading(true);

    axios
      .post(
        `${apiUrl}/user/change-password`,
        {
          currentPassword: currentPassword,
          newPassword: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        message.success("Mật khẩu đã được thay đổi thành công.");
        form.resetFields();
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.data) {
          message.error(error.response.data.error || "Đã xảy ra lỗi.");
        } else {
          message.error("Đã xảy ra lỗi khi thay đổi mật khẩu.");
        }
      });
  };

  return (
    <Form
      form={form}
      name="change_password"
      onFinish={onFinish}
      layout="vertical"
      initialValues={{ remember: true }}
      style={{ maxWidth: 400, margin: "0 auto" }}
    >
      <h3 style={{ fontWeight: 700 }}>Thay đổi mật khẩu</h3>
      <Form.Item
        name="currentPassword"
        label="Mật khẩu hiện tại"
        rules={[
          { required: true, message: "Vui lòng nhập mật khẩu hiện tại!" },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Nhập mật khẩu hiện tại"
        />
      </Form.Item>

      <Form.Item
        name="newPassword"
        label="Mật khẩu mới"
        rules={[
          { required: true, message: "Vui lòng nhập mật khẩu mới!" },
          {
            min: 6,
            message: "Mật khẩu mới phải có ít nhất 6 ký tự!",
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Nhập mật khẩu mới"
        />
      </Form.Item>

      <Form.Item
        name="confirmNewPassword"
        label="Xác nhận mật khẩu mới"
        dependencies={["newPassword"]}
        rules={[
          {
            required: true,
            message: "Vui lòng xác nhận mật khẩu mới!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("newPassword") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("Xác nhận mật khẩu mới không khớp!")
              );
            },
          }),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Xác nhận mật khẩu mới"
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{ width: "100%" }}
        >
          Xác nhận
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangePassword;
