import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  Table,
  Input,
  Form,
  Typography,
  Modal,
  Button,
  Spin,
  Alert,
  Col,
  Row,
  DatePicker,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";

const apiUrl = process.env.REACT_APP_API_URL;
const { Title } = Typography;

const initState = {
  time: "",
  date: "",
  transport_type: "",
  fromWarehouse: "",
  toWarehouse: "",
  responsiblePerson: "",
  status: "",
  notes: "",
  products: [],
};

const ReceivedTransferForm = () => {
  const [transferDetails, setTransferDetails] = useState(initState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const trcode = searchParams.get("trcode");

  useEffect(() => {
    const fetchTransferDetails = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/transfer/transfer-details/${trcode}`
        );

        const formattedData = formatData(response.data);
        setTransferDetails(formattedData);
        setLoading(false);
      } catch (error) {
        setError("Error fetching transfer details");
        setLoading(false);
      }
    };

    fetchTransferDetails();
  }, [trcode]);

  console.log(transferDetails);

  const formatData = (data) => {
    return {
      time: data.time,
      date: data.date,
      transport_type:
        data.transport_type === "lanh_vat_tu_san_xuat"
          ? "Lãnh Vật Tư Sản Xuất"
          : "Trả Vật Tư Sản Xuất",
      responsiblePerson: data.responsiblePerson,
      createdByFullName: data.createdByFullName,
      status: data.status,
      notes: data.notes,
      selectedProducts: data.products.map((product, index) => ({
        key: `${product.code}_${product.lot || index}_${product.date || index}`,
        code: product.code,
        name: product.name,
        category: product.category,
        unit: product.unit,
        quantity: product.quantity,
        initialDispatchedQuantity: product.dispatchedQuantity || 0, // Lưu giá trị ban đầu của dispatchedQuantity
        dispatchedQuantity: product.dispatchedQuantity || 0,
        receivedQuantity: product.receivedQuantity || 0,
        lot: product.lot || "",
        date: product.lotDate ? moment(product.lotDate, "DD/MM/YYYY") : null,
        isSubProduct: false,
      })),
    };
  };

  const handleConfirm = () => {
    Modal.confirm({
      title: "Xác nhận",
      content: "Bạn có chắc chắn muốn xác nhận thông tin này?",
      onOk: async () => {
        try {
          const values = await form.validateFields();

          // Nhóm các sản phẩm theo mã code
          const productGroups = transferDetails.selectedProducts.reduce(
            (groups, product) => {
              const { code } = product;
              if (!groups[code]) {
                groups[code] = [];
              }
              groups[code].push(product);
              return groups;
            },
            {}
          );

          // Kiểm tra tổng số lượng thực xuất của từng nhóm sản phẩm
          for (let code in productGroups) {
            const group = productGroups[code];
            const totalDispatchedQuantity = group.reduce((total, product) => {
              const dispatchedQuantity =
                values[`dispatchedQuantity_${product.key}`];
              return total + (parseFloat(dispatchedQuantity) || 0);
            }, 0);

            const requiredQuantity = group[0].quantity; // Giả sử tất cả sản phẩm trong nhóm có cùng số lượng yêu cầu

            if (totalDispatchedQuantity > requiredQuantity) {
              Modal.error({
                title: "Xác nhận thất bại",
                content: `Tổng số lượng thực xuất của sản phẩm mã ${code} vượt quá số lượng yêu cầu (${requiredQuantity}).`,
              });
              return;
            }
          }

          const updatedProducts = transferDetails.selectedProducts.map(
            (product) => ({
              ...product,
              dispatchedQuantity: values[`dispatchedQuantity_${product.key}`],
              receivedQuantity: values[`receivedQuantity_${product.key}`],
              lot: values[`lot_${product.key}`],
              date: values[`date_${product.key}`]
                ? values[`date_${product.key}`].format("DD/MM/YYYY")
                : "",
            })
          );

          const dataToSend = {
            transferId: trcode,
            selectedProducts: updatedProducts,
          };

          const token = localStorage.getItem("token");

          const response = await axios.put(
            `${apiUrl}/transfer/update-products`,
            dataToSend,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.status === 200) {
            Modal.success({
              title: "Xác nhận thành công",
              content: "Thông tin phiếu đã được cập nhật.",
            });
            window.location.href = "/";
          } else {
            throw new Error("Có lỗi xảy ra khi cập nhật thông tin");
          }
        } catch (error) {
          console.error("API Error:", error);
          Modal.error({
            title: "Xác nhận thất bại",
            content: "Đã xảy ra lỗi khi cập nhật thông tin phiếu.",
          });
        }
      },
      onCancel() {
        // Handle cancel logic if needed
      },
    });
  };

  const handleDuplicateRow = (index) => {
    const newProducts = [...transferDetails.selectedProducts];
    const newProduct = { ...newProducts[index] };

    newProduct.key = `${newProduct.code}_${newProduct.lot || index + 1}_${
      newProduct.date || index + 1
    }_${Date.now()}`;

    newProduct.dispatchedQuantity = 0;
    newProduct.isSubProduct = true;

    newProducts.splice(index + 1, 0, newProduct);
    setTransferDetails({
      ...transferDetails,
      selectedProducts: newProducts,
    });
  };

  const handleRemoveRow = (index) => {
    const newProducts = [...transferDetails.selectedProducts];
    newProducts.splice(index, 1);
    setTransferDetails({
      ...transferDetails,
      selectedProducts: newProducts,
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      width: "40px",
      align: "center",
      render: (_, __, index) => index + 1,
    },
    {
      title: "SKU",
      dataIndex: "code",
      key: "code",
      width: "60px",
      align: "center",
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
      width: "200px",
    },
    {
      title: "SL",
      dataIndex: "quantity",
      key: "quantity",
      width: "80px",
      align: "center",
    },
    {
      title: "ĐVT",
      dataIndex: "unit",
      key: "unit",
      width: "60px",
      align: "center",
    },
    {
      title: "Số lượng thực xuất",
      key: "dispatchedQuantity",
      width: "200px",
      render: (_, record, index) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Form.Item
            name={`dispatchedQuantity_${record.key}`}
            initialValue={
              record.dispatchedQuantity > 0
                ? record.dispatchedQuantity
                : record.quantity
            }
            rules={[
              {
                required: true,
                message: "Số lượng thực xuất không được để trống",
              },
              {
                validator: (_, value) =>
                  value > record.quantity
                    ? Promise.reject(
                        new Error(
                          "Số lượng thực xuất không được vượt quá yêu cầu"
                        )
                      )
                    : Promise.resolve(),
              },
            ]}
            style={{ flex: 1 }}
          >
            <Input
              type="number"
              step="0.01"
              placeholder="Nhập số lượng thực xuất"
              disabled={transferDetails.status === "inProgress"}
              style={{
                color: "red",
                textAlign: "center",
                fontWeight: "bold",
                width: "120px",
              }}
              onChange={(e) => {
                const newProducts = [...transferDetails.selectedProducts];
                newProducts[index].dispatchedQuantity =
                  parseFloat(e.target.value) || 0;
                setTransferDetails({
                  ...transferDetails,
                  selectedProducts: newProducts,
                });
              }}
            />
          </Form.Item>
          <span
            style={{
              marginLeft: "8px",
              fontWeight: "bold",
              paddingBottom: "25px",
            }}
          >
            /{" "}
            {record.initialDispatchedQuantity > 0
              ? record.initialDispatchedQuantity
              : record.quantity}{" "}
            {/* Hiển thị số lượng thực xuất ban đầu */}
          </span>
        </div>
      ),
    },
    {
      title: "Số lượng thực nhận",
      key: "receivedQuantity",
      width: "200px",
      render: (_, record, index) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Form.Item
            name={`receivedQuantity_${record.key}`}
            initialValue={
              transferDetails.status === "approved"
                ? 0
                : record.dispatchedQuantity // Tự động điền giá trị từ "Số lượng thực xuất"
            }
            rules={[
              {
                required: true,
                message: "Số lượng thực nhận không được để trống",
              },
            ]}
            style={{ flex: 1 }}
          >
            <Input
              type="number"
              step="0.01"
              placeholder="Nhập số lượng thực nhận"
              disabled={transferDetails.status === "approved"}
              style={{
                color: "red", // Màu chữ trong Input
                textAlign: "center",
                fontWeight: "bold",
                width: "120px",
              }}
              onChange={(e) => {
                const newProducts = [...transferDetails.selectedProducts];
                newProducts[index].receivedQuantity =
                  parseFloat(e.target.value) || 0;
                setTransferDetails({
                  ...transferDetails,
                  selectedProducts: newProducts,
                });
              }}
            />
          </Form.Item>
          <span
            style={{
              marginLeft: "8px",
              fontWeight: "bold",
              paddingBottom: "25px",
            }}
          >
            /{" "}
            {record.initialDispatchedQuantity > 0
              ? record.initialDispatchedQuantity
              : record.quantity}
          </span>
        </div>
      ),
    },
    {
      title: "Lot",
      key: "lot",
      width: "120px",

      render: (_, record, index) => (
        <Form.Item name={`lot_${record.key}`} initialValue={record.lot}>
          <Input
            placeholder="Nhập Lot"
            // disabled={
            //   transferDetails.status === "inProgress" && !record.isSubProduct
            // }
            disabled={true}
            onChange={(e) => {
              const newProducts = [...transferDetails.selectedProducts];
              newProducts[index].lot = e.target.value;
              setTransferDetails({
                ...transferDetails,
                selectedProducts: newProducts,
              });
            }}
            style={{ width: "150px" }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Date",
      key: "date",
      width: "120px",
      render: (_, record, index) => (
        <Form.Item name={`date_${record.key}`} initialValue={record.date}>
          <DatePicker
            format="DD/MM/YYYY"
            placeholder="Chọn ngày"
            // disabled={
            //   transferDetails.status === "inProgress" && !record.isSubProduct
            // }
            disabled={true}
            onChange={(date, dateString) => {
              const newProducts = [...transferDetails.selectedProducts];
              newProducts[index].date = date
                ? moment(dateString, "DD/MM/YYYY")
                : null;
              setTransferDetails({
                ...transferDetails,
                selectedProducts: newProducts,
              });
            }}
            style={{ width: "150px" }}
          />
        </Form.Item>
      ),
    },

    // {
    //   title: "Thao tác",
    //   key: "actions",
    //   width: "150px",
    //   render: (_, record, index) => (
    //     <div>
    //       <Button
    //         type="dashed"
    //         icon={<PlusOutlined />}
    //         onClick={() => handleDuplicateRow(index)}
    //         style={{ marginRight: 8 }}
    //       />
    //       {record.isSubProduct && (
    //         <Button
    //           type="danger"
    //           icon={<DeleteOutlined />}
    //           onClick={() => handleRemoveRow(index)}
    //         />
    //       )}
    //     </div>
    //   ),
    // },
  ];

  if (loading) return <Spin size="large" />;
  if (error) return <Alert message={error} type="error" showIcon />;

  return (
    <div style={{ padding: "16px" }}>
      <Title level={3} style={{ textAlign: "center" }}>
        Thông tin phiếu nhận hàng
      </Title>
      <Form form={form} layout="vertical">
        <Table
          columns={columns}
          dataSource={transferDetails.selectedProducts}
          rowKey="key"
          pagination={false}
          tableLayout="auto"
          scroll={{ x: 1000 }}
          style={{ width: "100%" }}
        />
        <Row justify="center" style={{ marginTop: "20px" }}>
          <Col span={24}>
            <Form.Item>
              <Button
                disabled={
                  transferDetails.status != "approved" &&
                  transferDetails.status != "inProgress"
                }
                type="primary"
                size="large"
                onClick={handleConfirm}
                block
              >
                Xác nhận
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ReceivedTransferForm;
