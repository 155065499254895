import React, { useState, useEffect, useRef } from "react";
import "./TransferSlipDetail.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logo from "../assets/images/btf_logo.png";
import logoSimply from "../assets/images/logo-simply.jpg";
import axios from "axios";
import { useLocation } from "react-router-dom";
import JsBarcode from "jsbarcode";
import config from "../config";
import { Button, Modal } from "antd";
import toast from "react-hot-toast";
const apiUrl = process.env.REACT_APP_API_URL;

const initState = {
  time: "",
  date: "",
  transport_type: "",
  receiver_name: "",
  notes: "",
  createdByFullName: "",
  selectedProducts: [],
};

const TransferSlipDetail = ({ userInfo }) => {
  const [transferDetails, setTransferDetails] = useState(initState);
  const [dataTransfer, setDataTransfer] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const trcode = searchParams.get("trcode");
  const barcodeRef = useRef(null);

  const statusMapping = {
    pending: "Đang chờ duyệt",
    approved: "Đã duyệt",
    lotFilled: "Đã điền Lot",
    inProgress: "Đã bàn giao",
    completed: "Hoàn thành",
    cancelled: "Đã hủy",
  };

  const formatData = (data) => {
    const formatDateTime = (datetime) => {
      if (!datetime) return "";

      const dateObj = new Date(datetime);

      // Thêm 7 giờ để điều chỉnh thành GMT+7
      dateObj.setHours(dateObj.getHours() + 7);

      const day = String(dateObj.getDate()).padStart(2, "0");
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      const year = dateObj.getFullYear();
      const timePart = dateObj.toTimeString().split(" ")[0]; // Lấy phần giờ:phút:giây

      return `${day}/${month}/${year}, ${timePart}`;
    };

    const transportTypeMapping = {
      lanh_vat_tu_san_xuat: "Lãnh Vật Tư Sản Xuất",
      tra_vat_tu_dang_do: "Trả Vật Tư Sản Xuất",
      xuat_ban_thanh_pham: "Lãnh Bán Thành Phẩm",
      nhap_ban_thanh_pham: "Trả Bán Thành Phẩm",
    };

    return {
      time: data.time,
      date: data.date,
      status: statusMapping[data.status] || data.status,
      transport_type:
        transportTypeMapping[data.transport_type] || data.transport_type,
      receiver_name: data.responsiblePerson,
      createdByFullName: data.createdByFullName,
      approvedByFullName: data.approvedByFullName,
      handoverPersonFullName: data.handoverPersonFullName,
      receiverPersonFullName: data.receiverPersonFullName,
      notes: data.notes,
      createDate: formatDateTime(data.createDate),
      handoverDate: formatDateTime(data.handoverDate),
      receiverDate: formatDateTime(data.receiverDate),
      approvedDate: formatDateTime(data.approvedDate),
      selectedProducts: data.products.map((product) => ({
        code: product.code,
        name: product.name,
        unit: product.unit,
        quantity: product.quantity,
        lot: product.lot,
        lotDate: product.lotDate,
        dispatchedQuantity: product.dispatchedQuantity,
        receivedQuantity: product.receivedQuantity,
      })),
    };
  };

  const exportPDF = (printDirectly = false) => {
    const input = document.getElementById("transfer-slip");

    const a4Width = 595.28;
    const a4Height = 841.89;

    input.style.width = `${a4Width}px`;

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: "a4",
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = imgWidth / pdfWidth;

      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight / ratio);
      heightLeft -= pdfHeight * ratio;

      while (heightLeft > 0) {
        position -= pdfHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight / ratio);
        heightLeft -= pdfHeight * ratio;
      }

      if (printDirectly) {
        const pdfBlob = pdf.output("bloburl");
        window.open(pdfBlob, "_blank"); // Mở PDF trong một tab mới để in
      } else {
        pdf.save("transfer-slip.pdf");
      }
    });
  };

  const handleApproveTransfer = async (transferId) => {
    Modal.confirm({
      title: "Xác nhận duyệt phiếu",
      content: "Bạn có chắc chắn muốn duyệt phiếu này không?",
      okText: "Xác nhận",
      cancelText: "Hủy bỏ",
      onOk: async () => {
        const token = localStorage.getItem("token");

        try {
          const response = await axios.post(
            `${apiUrl}/transfer/update-status`,
            {
              transferId,
              status: "approved",
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          toast.success("Phiếu đã được duyệt thành công!", {
            position: "top-right",
          });

          fetchTransferDetails();
        } catch (error) {
          console.error("Error approving transfer:", error);
          toast.error("Có lỗi xảy ra khi duyệt phiếu. Vui lòng thử lại.", {
            position: "top-right",
          });
        }
      },
    });
  };
  const fetchTransferDetails = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/transfer/transfer-details/${trcode}`
      );

      setDataTransfer(response.data);

      const formattedData = formatData(response.data);
      setTransferDetails(formattedData);

      setLoading(false);
    } catch (error) {
      setError("Error fetching transfer details");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransferDetails();
  }, [trcode]);

  useEffect(() => {
    if (dataTransfer.transferId) {
      JsBarcode(barcodeRef.current, dataTransfer.transferId, {
        format: "CODE128",
        displayValue: true,
        fontSize: 18,
        height: 50,
      });
    }
  }, [dataTransfer.transferId]);

  return (
    <div>
      <div id="transfer-slip" className="transfer-slip">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "8px",
          }}
        >
          <div style={{ flex: 1, textAlign: "left" }}>
            <h3 style={{ fontSize: "13px" }}>
              <strong>CÔNG TY CỔ PHẦN THỰC PHẨM BÌNH TÂY</strong>
            </h3>
            <p style={{ fontSize: "10px" }}>
              <strong>Địa chỉ:</strong> Khu A, Lô L, Đường số 08, KCX Tân Thuận,
              Phường Tân Thuận Đông, Quận 7, Tp. Hồ Chí Minh
            </p>

            <p style={{ fontSize: "10px" }}>
              <strong>Email:</strong> info@binhtayfood.com
            </p>
            <p style={{ fontSize: "10px" }}>
              <strong>Website:</strong> binhtayfood.com
            </p>
          </div>

          <div style={{ textAlign: "right", flex: 1, marginTop: "40px" }}>
            <img src={logo} alt="Logo" className="logo" />
            <img src={logoSimply} alt="Logo" className="logo" />
          </div>
        </div>

        <div
          className="header"
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          <h4 style={{ fontWeight: "700", textTransform: "uppercase" }}>
            Phiếu {transferDetails.transport_type}: {trcode}
          </h4>
        </div>

        <div
          className="transfer-slip-info"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1 }}>
            {/* <p>
              <strong>Loại điều chuyển:</strong>{" "}
              {transferDetails.transport_type}
            </p> */}
            <p>
              <strong>Người tạo phiếu:</strong>{" "}
              {transferDetails.createdByFullName}
            </p>

            {/* <p>
              <strong>Người duyệt:</strong> {transferDetails.approvedByFullName}
            </p> */}
            {/* <p>
              <strong>Bàn giao:</strong>{" "}
              {transferDetails.handoverPersonFullName}
            </p> */}
            <p>
              <strong>Ngày tạo phiếu:</strong> {transferDetails.date}{" "}
              <span>({transferDetails.time})</span>
            </p>
            <p>
              <strong>Trạng thái:</strong> {transferDetails.status}
            </p>
            <p>
              <strong>Ghi chú:</strong> {transferDetails.notes}
            </p>
          </div>
          <div style={{ marginLeft: "20px" }}>
            <svg ref={barcodeRef}></svg>
          </div>
        </div>

        <div className="transfer-slip-table-wrapper">
          <table className="transfer-slip-table">
            <thead>
              <tr>
                <th style={{ width: "20px" }}>#</th>
                <th style={{ width: "20px" }}>SKU</th>
                <th style={{ width: "140px" }}>Tên</th>
                <th style={{ width: "40px" }}>ĐVT</th>
                <th style={{ width: "40px" }}>SL yêu cầu</th>
                <th style={{ width: "60px" }}>Thực xuất</th>
                <th style={{ width: "60px" }}>Thực nhận</th>
                <th style={{ width: "80px" }}>Lot</th>
                <th>Hạn sử dụng</th>
              </tr>
            </thead>
            <tbody>
              {transferDetails.selectedProducts.map((product, index) => {
                const isDuplicateSKU =
                  index > 0 &&
                  product.code ===
                    transferDetails.selectedProducts[index - 1].code;

                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{!isDuplicateSKU ? product.code : "_"}</td>
                    <td style={{ padding: "15px" }}>
                      {!isDuplicateSKU ? product.name : "_"}
                    </td>
                    <td>{!isDuplicateSKU ? product.unit : "_"}</td>
                    {/* <td>{product.quantity}</td> */}
                    <td>{!isDuplicateSKU ? product.quantity : "_"}</td>
                    <td>{product.dispatchedQuantity}</td>
                    <td>{product.receivedQuantity}</td>
                    <td>{product.lot}</td>
                    <td>{product.lotDate}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div
          className="transfer-slip-signature"
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "20px",
          }}
        >
          <div style={{ textAlign: "center", flex: 1 }}>
            <p style={{ marginBottom: "80px" }}>
              <strong>Người duyệt</strong>
            </p>
            <strong>{transferDetails.approvedByFullName}</strong>
            <p>{transferDetails.approvedDate}</p> {/* Hiển thị approvedDate */}
            {/* Add the approval button if the user has approval permission and the status is pending */}
            {userInfo.permissions.includes("approve") &&
              transferDetails.status === "Đang chờ duyệt" && (
                <Button
                  type="primary"
                  onClick={() => handleApproveTransfer(trcode)}
                  style={{ marginTop: "10px" }}
                >
                  Duyệt phiếu
                </Button>
              )}
          </div>

          <div style={{ textAlign: "center", flex: 1 }}>
            <p style={{ marginBottom: "80px" }}>
              <strong>Người bàn giao</strong>
            </p>
            <strong>{transferDetails.handoverPersonFullName}</strong>
            <p>{transferDetails.handoverDate}</p>{" "}
            {/* Hiển thị handoverDate hoặc createDate */}
          </div>

          <div style={{ textAlign: "center", flex: 1 }}>
            <p style={{ marginBottom: "80px" }}>
              <strong>Người nhận bàn giao</strong>
            </p>
            <strong>{transferDetails.receiverPersonFullName}</strong>
            <p>{transferDetails.receiverDate}</p> {/* Hiển thị receiverDate */}
          </div>
        </div>
      </div>
      <button onClick={() => exportPDF(false)}>Xuất PDF</button>
    </div>
  );
};

export default TransferSlipDetail;
