import React, { useState, useEffect } from "react";
import "./TransferTable.css";
import {
  Input,
  Button,
  Dropdown,
  Menu,
  Select,
  DatePicker,
  Modal,
  Tabs,
} from "antd";
import toast from "react-hot-toast";
import axios from "axios";
import moment from "moment";

import ScanToConfirmModal from "../components/ScanToConfirmModal";

const apiUrl = process.env.REACT_APP_API_URL;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const TransferTable = ({
  userInfo,
  transfers,
  setSearchTerm,
  setFilterType,
  selectedDates,
  setSelectedDates,
  setSortOption,
  fetchDataTransfer,
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState(
    transfers.map((transfer) => transfer.transferId)
  );
  const [scanVisible, setScanVisible] = useState(false);
  const [activeTransferId, setActiveTransferId] = useState(null);
  const role = localStorage.getItem("role");

  useEffect(() => {
    setExpandedRowKeys(transfers.map((transfer) => transfer.transferId));
  }, [transfers]);

  console.log(transfers);

  const allCount = transfers.length;
  const pendingCount = transfers.filter(
    (transfer) => transfer.status === "pending"
  ).length;
  const approvedCount = transfers.filter(
    (transfer) => transfer.status === "approved"
  ).length;
  const inProgressCount = transfers.filter(
    (transfer) => transfer.status === "inProgress"
  ).length;
  const completedCount = transfers.filter(
    (transfer) => transfer.status === "completed"
  ).length;
  const cancelledCount = transfers.filter(
    (transfer) => transfer.status === "cancelled"
  ).length;

  const toggleRow = (transferId) => {
    setExpandedRowKeys((prevKeys) => {
      if (prevKeys.includes(transferId)) {
        return prevKeys.filter((key) => key !== transferId);
      } else {
        return [...prevKeys, transferId];
      }
    });
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "completed":
        return "completed-row";
      case "cancelled":
        return "cancelled-row";
      case "pending":
        return "pending-row";
      case "approved":
        return "approved-row";
      case "inProgress":
        return "in-progress-row";
      default:
        return "";
    }
  };

  const getTransportTypeLabel = (type) => {
    switch (type) {
      case "lanh_vat_tu_san_xuat":
        return "Lãnh Vật Tư Sản Xuất ";
      case "tra_vat_tu_dang_do":
        return "Trả Vật Tư Sản xuất";
      case "xuat_ban_thanh_pham":
        return "Lãnh Bán Thành Phẩm";
      case "nhap_ban_thanh_pham":
        return "Trả Bán Thành Phẩm";
      default:
        return type;
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case "pending":
        return "Đang chờ duyệt";
      case "approved":
        return "Đã duyệt";
      case "lotFilled":
        return "Đã điền Lot";
      case "inProgress":
        return "Đã bàn giao";
      case "completed":
        return "Hoàn thành";
      case "cancelled":
        return "Đã hủy";
      default:
        return "";
    }
  };

  const formatDateTime = (time, date) => {
    const formattedDate = date.split("-").join("-");
    return `${formattedDate} (${time})`;
  };

  const handleScanToConfirm = (transferId, transferDate, transferTime) => {
    // Lấy ngày và thời gian hiện tại
    const currentDate = moment().startOf("day");

    console.log(transferId);



    const currentTime = moment();

    // Chuyển đổi ngày và thời gian của phiếu sang định dạng moment
    const transferDateTime = moment(
      `${transferDate} ${transferTime}`,
      "DD-MM-YYYY HH:mm:ss"
    );

    // So sánh ngày của phiếu với ngày hiện tại
    if (transferDateTime.isAfter(currentDate, "day")) {
      Modal.info({
        title: "Thông báo",
        content: `Phiếu này là phiếu cho ngày ${transferDate}, bạn không thể bàn giao cho ngày hôm nay.`,
        okText: "Đóng",
      });
    } else {
      Modal.confirm({
        title: "Xác nhận bàn giao/nhận bàn giao",
        content: "Bạn có chắc chắn muốn thực hiện hành động này?",
        okText: "Xác nhận",
        cancelText: "Hủy",
        onOk: () => {
          window.location.href = `/received-transfer?trcode=${transferId}`;
        },
      });
    }
  };

  const handleLotFill = (transferId) => {
    Modal.confirm({
      title: "Xác nhận điền Lot Date cho phiếu",
      content: "Bạn có chắc chắn muốn thực hiện hành động này?",
      okText: "Xác nhận",
      cancelText: "Hủy",
      onOk: () => {
        window.location.href = `/change-transfer?trcode=${transferId}`;
      },
    });
  };

  const closeScanModal = () => {
    setScanVisible(false);
  };

  const handleCancelTransfer = async (transferId) => {
    if (role === "staff") {
      toast.error("Bạn không có quyền để thao tác");
      return;
    }

    Modal.confirm({
      title: "Xác nhận hủy phiếu",
      content: "Bạn có chắc chắn muốn hủy phiếu này?",
      okText: "Hủy phiếu",
      cancelText: "Không",
      onOk: async () => {
        const token = localStorage.getItem("token");

        try {
          const response = await axios.post(
            `${apiUrl}/transfer/update-status`,
            {
              transferId,
              status: "cancelled",
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          toast.success(response.data.message, { position: "top-right" });
          fetchDataTransfer();
        } catch (error) {
          console.error("Error cancelling transfer:", error);
          toast.error("Có lỗi xảy ra khi hủy phiếu. Vui lòng thử lại.", {
            position: "top-right",
          });
        }
      },
    });
  };

  const handleApproveTransfer = async (transferId) => {
    Modal.confirm({
      title: "Xác nhận duyệt phiếu",
      content: "Bạn có chắc chắn muốn duyệt phiếu này?",
      okText: "Duyệt",
      cancelText: "Hủy",
      onOk: async () => {
        const token = localStorage.getItem("token");

        try {
          const response = await axios.post(
            `${apiUrl}/transfer/update-status`,
            {
              transferId,
              status: "approved",
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          toast.success("Phiếu đã được duyệt thành công!", {
            position: "top-right",
          });

          fetchDataTransfer();
        } catch (error) {
          console.error("Error approving transfer:", error);
          toast.error("Có lỗi xảy ra khi duyệt phiếu. Vui lòng thử lại.", {
            position: "top-right",
          });
        }
      },
    });
  };

  const handleConfirmCompletion = (transferId) => {
    window.location.href = `/received-transfer?trcode=${transferId}`;
  };

  const menu = (transferId, status, ticketType, date, time, isFilled) => (
    <Menu>
      {(ticketType === "lanh_vat_tu_san_xuat" ||
        ticketType === "xuat_ban_thanh_pham") && (
        <>
          {userInfo.permissions.includes("handoverProductionTicket") &&
            status === "approved" && (
              <Menu.Item
                key="1"
                onClick={() => handleScanToConfirm(transferId, date, time)}
              >
                Bàn giao
              </Menu.Item>
            )}

          {userInfo.permissions.includes("receiveProductionTicket") &&
            status === "inProgress" && (
              <Menu.Item
                key="2"
                onClick={() => handleScanToConfirm(transferId)}
              >
                Nhận bàn giao
              </Menu.Item>
            )}
        </>
      )}

      {(ticketType === "tra_vat_tu_dang_do" ||
        ticketType === "nhap_ban_thanh_pham") && (
        <>
          {userInfo.permissions.includes("handoverReturnTicket") &&
            status === "approved" && (
              <Menu.Item
                key="3"
                onClick={() => handleScanToConfirm(transferId, date, time)}
              >
                Bàn giao
              </Menu.Item>
            )}

          {userInfo.permissions.includes("receiveReturnTicket") &&
            status === "inProgress" && (
              <Menu.Item
                key="4"
                onClick={() => handleScanToConfirm(transferId)}
              >
                Nhận bàn giao
              </Menu.Item>
            )}
        </>
      )}

      {userInfo.permissions.includes("approve") && status === "pending" && (
        <Menu.Item key="5" onClick={() => handleApproveTransfer(transferId)}>
          Duyệt phiếu
        </Menu.Item>
      )}
      {userInfo.permissions.includes("cancel") && status === "pending" && (
        <Menu.Item key="6" onClick={() => handleCancelTransfer(transferId)}>
          Hủy phiếu
        </Menu.Item>
      )}
      {userInfo.permissions.includes("fillLot") &&
        status != "pending" &&
        !isFilled && (
          <Menu.Item key="7" onClick={() => handleLotFill(transferId)}>
            Điền Lot
          </Menu.Item>
        )}
    </Menu>
  );

  const renderTable = (filteredTransfers) => {
    return (
      <div className="transfer-table-container">
        <table className="transfer-table">
          <thead>
            <tr>
              <th></th> {/* Cột dành cho nút */}
              <th style={{ minWidth: "100px" }}>Mã Phiếu</th>
              <th>Thời Gian</th>
              <th>Loại Điều Chuyển</th>
              {/* <th>Kho Xuất</th>
              <th>Kho Nhận</th> */}
              <th>Người Tạo Phiếu</th>
              <th>Trạng Thái</th>
              <th>Hành động</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransfers.map((transfer) => (
              <React.Fragment key={transfer._id}>
                <tr className={`main-row ${getStatusClass(transfer.status)}`}>
                  <td>
                    <button
                      className="expand-btn"
                      onClick={() => toggleRow(transfer.transferId)}
                    >
                      {expandedRowKeys.includes(transfer.transferId)
                        ? "-"
                        : "+"}
                    </button>
                  </td>
                  <td
                    onClick={() =>
                      (window.location.href = `/transfer-slip-detail?trcode=${transfer.transferId}`)
                    }
                    data-label="Mã phiếu"
                  >
                    {transfer.transferId}
                  </td>
                  <td data-label="Thời Gian & Ngày">
                    {formatDateTime(transfer.time, transfer.date)}
                  </td>
                  <td data-label="Loại điều chuyển">
                    {getTransportTypeLabel(transfer.transport_type)}
                  </td>
                  {/* <td data-label="Kho xuất">{transfer.fromWarehouse}</td>
                  <td data-label="Kho nhận">{transfer.toWarehouse}</td> */}
                  <td data-label="Người tạo phiếu">
                    {transfer.responsiblePerson}
                  </td>
                  <td data-label="Trạng thái">
                    <span
                      className={`status-label ${getStatusClass(
                        transfer.status
                      )}`}
                    >
                      {getStatusLabel(transfer.status)}
                    </span>
                  </td>
                  <td data-label="Hành động">
                    <Dropdown
                      overlay={menu(
                        transfer.transferId,
                        transfer.status,
                        transfer.transport_type,
                        transfer.date,
                        transfer.time,
                        transfer.isFilled
                      )}
                    >
                      <Button>...</Button>
                    </Dropdown>
                  </td>
                </tr>
                {expandedRowKeys.includes(transfer.transferId) && (
                  <tr>
                    <td colSpan="9">
                      <div
                        className={`expandable-content ${
                          expandedRowKeys.includes(transfer.transferId)
                            ? "expanded"
                            : ""
                        }`}
                      >
                        <table className="inner-table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>SKU</th>
                              <th>Tên</th>
                              <th>ĐVT</th>
                              <th>SL Yêu Cầu</th>
                              <th>Thực Xuất</th>
                              <th>Thực Nhận</th>
                              <th>Lot</th>
                              <th>Hạn Sử Dụng</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transfer.products.map((product, index) => {
                              const isDuplicateSKU =
                                index > 0 &&
                                product.code ===
                                  transfer.products[index - 1].code;

                              return (
                                <tr key={product._id}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {!isDuplicateSKU ? product.code : "_"}
                                  </td>
                                  <td>
                                    {!isDuplicateSKU ? product.name : "_"}
                                  </td>
                                  <td>
                                    {!isDuplicateSKU ? product.unit : "_"}
                                  </td>
                                  <td>
                                    {!isDuplicateSKU ? product.quantity : "_"}
                                  </td>
                                  <td>
                                    {product.dispatchedQuantity % 1 === 0
                                      ? product.dispatchedQuantity
                                      : product.dispatchedQuantity.toFixed(2)}
                                  </td>
                                  <td>
                                    {product.receivedQuantity % 1 === 0
                                      ? product.receivedQuantity
                                      : product.receivedQuantity.toFixed(2)}
                                  </td>
                                  <td>{product.lot}</td>
                                  <td>{product.lotDate}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {userInfo.permissions.includes("approve") &&
                          transfer.status === "pending" && (
                            <div
                              style={{ marginTop: "10px", textAlign: "right" }}
                            >
                              <Button
                                type="primary"
                                onClick={() =>
                                  handleApproveTransfer(transfer.transferId)
                                }
                              >
                                Duyệt phiếu
                              </Button>
                            </div>
                          )}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="transfer-table-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="filter-section">
          <Input
            placeholder="Tìm kiếm mã phiếu"
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginRight: "8px", width: "200px" }}
          />
          <Select
            placeholder="Chọn loại điều chuyển"
            onChange={(value) => setFilterType(value)}
            style={{ marginRight: "8px", width: "200px" }}
          >
            <Option value="">Hiển thị tất cả</Option>
            <Option value="lanh_vat_tu_san_xuat">Lãnh Vật Tư Sản Xuất</Option>
            <Option value="tra_vat_tu_dang_do">Trả Vật Tư Sản Xuất</Option>
            <Option value="xuat_ban_thanh_pham">Lãnh Bán Thành Phẩm</Option>
            <Option value="nhap_ban_thanh_pham">Trả Bán Thành Phẩm</Option>
          </Select>
          <RangePicker
            value={selectedDates} // Giá trị mặc định là ngày hôm nay cho cả hai ô
            onChange={(dates) => setSelectedDates(dates)}
            style={{ marginRight: "8px" }}
            placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
          />
          <Select
            placeholder="Sắp xếp dữ liệu"
            onChange={(value) => setSortOption(value)}
            style={{ marginRight: "8px", width: "250px" }}
          >
            <Option value="time_desc">Thời gian (Mới nhất)</Option>
            <Option value="time_asc">Thời gian (Cũ nhất)</Option>
            {/* <Option value="status_priority">
              Trạng thái (Ưu tiên Đang chờ)
            </Option> */}
          </Select>
        </div>

        {userInfo.permissions.includes("create") && (
          <div className="action-section">
            <Button
              onClick={() => (window.location.href = "create-transfer")}
              type="primary"
            >
              Tạo phiếu mới
            </Button>
          </div>
        )}
      </div>

      <Tabs
        defaultActiveKey="1"
        className="custom-tabs"
        tabPosition="top"
        type="card"
        size="small" // Thay đổi size thành 'default' hoặc 'large'
        tabBarGutter={1} // Điều chỉnh giảm tabBarGutter
        style={{ overflowX: "auto", whiteSpace: "nowrap" }} // Cho phép cuộn ngang
      >
        <TabPane
          tab={
            <div>
              Tất cả <span className="tab-count">{allCount}</span>
            </div>
          }
          key="1"
        >
          {renderTable(transfers)}
        </TabPane>
        <TabPane
          tab={
            <div>
              Đang chờ duyệt <span className="tab-count">{pendingCount}</span>
            </div>
          }
          key="2"
        >
          {renderTable(
            transfers.filter((transfer) => transfer.status === "pending")
          )}
        </TabPane>
        <TabPane
          tab={
            <div>
              Đã duyệt <span className="tab-count">{approvedCount}</span>
            </div>
          }
          key="3"
        >
          {renderTable(
            transfers.filter((transfer) => transfer.status === "approved")
          )}
        </TabPane>
        {/* <TabPane
          tab={
            <div>
              Đã điền Lot <span className="tab-count">{approvedCount}</span>
            </div>
          }
          key="4"
        >
          {renderTable(
            transfers.filter((transfer) => transfer.status === "lotFilled")
          )}
        </TabPane> */}
        <TabPane
          tab={
            <div>
              Đã bàn giao <span className="tab-count">{inProgressCount}</span>
            </div>
          }
          key="5"
        >
          {renderTable(
            transfers.filter((transfer) => transfer.status === "inProgress")
          )}
        </TabPane>
        <TabPane
          tab={
            <div>
              Hoàn thành <span className="tab-count">{completedCount}</span>
            </div>
          }
          key="6"
        >
          {renderTable(
            transfers.filter((transfer) => transfer.status === "completed")
          )}
        </TabPane>
        <TabPane
          tab={
            <div>
              Đã hủy <span className="tab-count">{cancelledCount}</span>
            </div>
          }
          key="7"
        >
          {renderTable(
            transfers.filter((transfer) => transfer.status === "cancelled")
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default TransferTable;
